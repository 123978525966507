$breakpoint-tablet: 1200px;
$breakpoint-mobile: 768px;

$web-font-large: 57px;
$web-font-medium: 35px;
$web-font-smallplus: 30px;
$web-font-small: 22px;
$web-font-extrasmall: 16px;

$tablet-font-large: 50px;
$tablet-font-mediumplus: 30px;
$tablet-font-medium: 22px;
$tablet-font-smallplus: 19px;
$tablet-font-small: 16px;
$tablet-font-extrasmall: 12px;


$mobile-font-large: 35px;
$mobile-font-mediumplus: 30px;
$mobile-font-medium: 22px;
$mobile-font-smallplus: 19px;
$mobile-font-small: 16px;
$mobile-font-extrasmall: 12px;




$red:  #f92f26;
$green: #95da42;
$cyan:    rgb(0, 172, 157);
$grey:    rgb(51, 51, 51);
$black:   rgb(38, 38, 38);
$base:   #fceded;
$lightGrey:    rgb(235, 235, 235);

$BASE_FONT_SIZE: 16px;

$breakpointMega: 1600px;
$breakpointLarge: 990px;
$breakpointMed: 767px;