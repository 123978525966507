@import "./utils/variables";
@import url("https://use.typekit.net/mtb7txk.css");

.body-overlay {
    position: fixed; 
    // display: none; 
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 2; 
    // cursor: pointer; 
}

.App {
    text-align: center;
    font-family: "canada-type-gibson",sans-serif;
    background: #faf8f0;
    height: 100%;
    .green-text {
        color: #95da42;
    }
    .red-text {
        color: #f32e27;
    }
    .blue-text {
        color: #4b59c4;
    }
    .white-text {
        color: #ffffff;
    }
    .scroll-up {
        bottom: 100px !important;
    }
    .bolder-text {
        font-weight: 500;
    }
}
.social-sidebar {
    display: none;
}
.title-nav {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .social-media {
        align-self: center;
        a {
            display: inline-block;
            margin: 5px;
            img {
                width: 40px;
            }
        }
    }
    #menuToggle {
        display: none;
    }
    .title-logo {
        flex-grow: 1;
        cursor: pointer;
        img {
            margin-top: 15px;
        }
    }
    .title-name {
        margin-left: 150px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        color: #66c666;
        font-family: "Baloo Thambi";
        font-size: 56px;
        font-weight: 400;
        cursor: pointer;
    }
}
.nav-links {
    margin-top: 30px;   
    flex-grow: 1;
    z-index: 999;
    .nav-link {
        cursor: pointer;
        margin: 0px 22px;
        width: 45px;
        height: 20px;
        color: #000;
        text-decoration: none;
        /* font-family: "Abril Titling Semi Cnd Sb"; */
        font-size: 23px;
        font-weight: 500;
        letter-spacing: -0.34px;
    }
    .dropdown-parent {
        position: relative;
        .nav-dropdown {
            font-size: 20px;
            text-align: left;
            position: absolute;
            left: 0;
            width: 100%;
            background-color: #fff;
            border: 1px solid #ddd;
            .nav-dropdown-item {
                z-index: 9999;
                display: block;
                padding: 8px;
                text-decoration: none;
                color: #000;
            }
            .nav-dropdown-item:hover {
                background-color: #d6d6d6;
            }
        }
    }
}

@font-face {
    font-family: 'Baloo Thambi';
    src: url('/fonts/Baloo_Thambi/BalooThambi-Regular.ttf');
}

@media screen and (max-width: $breakpoint-tablet) {
    .title-nav {
        align-items: center;
        justify-content: flex-start;
        .title-name {

            margin-left: 0;
        }

    /* hamburger menu styles start */
        #menuToggle {
            display: block;
            position: relative;
            margin-right: 30px;
            // top: 50px;
            // left: 50px;
            text-align: left;
            z-index: 1;
            
            -webkit-user-select: none;
            user-select: none;
        }

        #menuToggle a {
            text-decoration: none;
            color: #232323;
            transition: color 0.3s ease;
        }

        #menuToggle a:hover {
            color: tomato;
        }


        #menuToggle input {
            display: block;
            width: 40px;
            height: 32px;
            position: absolute;
            top: -7px;
            left: -5px;
    
            cursor: pointer;
            
            opacity: 0; /* hide this */
            z-index: 22; /* and place it over the hamburger */
            
            -webkit-touch-callout: none;
        }

/*
 * hamburger icon 
 */
        #menuToggle span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;
            
            background: #cdcdcd;
            border-radius: 3px;
            
            z-index: 1;
            
            transform-origin: 4px 0px;
            
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        opacity 0.55s ease;
        }

        #menuToggle span:first-child {
            transform-origin: 0% 0%;
        }

        #menuToggle span:nth-last-child(2) {
            transform-origin: 0% 100%;
        }

        /* 
        * Transform all the slices of hamburger
        * into a crossmark.
        */
        #menuToggle input:checked ~ span {
            opacity: 1;
            transform: rotate(45deg) translate(-2px, -1px);
            background: #232323;
        }

        /*
        * hide the middle one.
        */
        #menuToggle input:checked ~ span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
        }

        /*
        * the last one should go the other direction
        */
        #menuToggle input:checked ~ span:nth-last-child(2) {
            transform: rotate(-45deg) translate(0, -1px);
        }

        /*
        * Make this absolute positioned
        * at the top left of the screen
        */
        #menu {
            position: absolute;
            width: 200px;
            margin: -100px 0 0 -50px;
            padding: 50px;
            padding-top: 125px;
            // z-index: 12;
            
            background: #faf8f0;
            height: 100vh;
            list-style-type: none;
            -webkit-font-smoothing: antialiased;
            /* to stop flickering of text in safari */
            
            transform-origin: 0% 0%;
            transform: translate(-100%, 0);
            
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        }

        #menu li {
            padding: 10px;
            font-size: 22px;
        }

        /*
        * slide it in from the left
        */
        #menuToggle input:checked ~ ul{
            transform: none;
        }
        /* hamburger menu styles end*/

    }

    .nav-links {
        display: none;
    }
}

@media (max-width: $breakpoint-mobile) {
    .social-sidebar {
        display: block;
        border-radius: 10px 0 0 10px;
        position: fixed;
        z-index: 11;
        right: 0%;
        padding: 3px;
        .social-list {
            margin: 0px;
            .social-icon {
                margin: 1px;
                background: #fff;
                img {
                    width: 35px;
                }
            }
        }
    }
    .collapse-sidebar {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        // right: 0%;
        // transition: left 250ms;
    }
    .show-sidebar {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        // right: 0%;
        // transition: right 250ms;
    }
    .title-nav {
        justify-content: space-between;
        // background-color: #fceded;
        padding: 10px;
        .social-media {
            display: none;
        }
        .title-logo {
            img {
                margin-top: 5px;
                width: 40px;
            }
        }
        .title-name {
            /* Style for "PitchN" */
            color: #95da42;
            font-family: "Baloo Thambi";
            font-size: $mobile-font-large;
            font-weight: 400;
            margin-left: 0;
        }
    }
    .nav-links {
        margin-top: 15px;   
        .nav-link {
            margin: 0px 5px;
            font-size: $mobile-font-extrasmall;
            font-weight: bold;
        }
    }
}