@import "../utils/variables";

.econsent-content {
    text-align: left;
    background: #fff;
    font-size: 18px;
    padding: 50px 100px;
    .title {
        text-align: center;
        margin: 50px auto;
    }
    div {
        width: 85%;
        margin: auto;
    }
    h2, h3, strong {
        font-weight: 600;
    }

    @media (max-width: $breakpoint-mobile) {
        padding: 50px 20px;    
    }
}